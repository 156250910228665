type TBody = {
  id: number;
  nombre: string;
}

export class Ciudad {
  #id:number;
  #nombre: string;

  get id(){
    return this.#id
  }

  get nombre(){
    return this.#nombre
  }

  constructor({id,nombre}:TBody){
    this.#id = id
    this.#nombre = nombre
  }

  static toDomain({ id, nombre }: { id: number; nombre: string; }) {
    return new Ciudad({
      id,
      nombre,
    })
  }
}
