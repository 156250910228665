import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'

export default [
  {
    path: '/blog',
    name: 'blog',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/blog/form/:id?',
    name: 'blog.form',
    component: ()=>import('./formulario'),
    beforeEnter: [authMiddleware,userEnterRoute]
  },
]