type TRaw = {
  id: number;
  esActivo: boolean;
  nombre: string;
  redId: number;
  color: string;
  cantidadKilometros: number;
  canRecogidaFecha: boolean;
  canEnvioFecha: boolean;
  radioKm: number;
}
type TBody = {
  id: number;
  esActivo: boolean;
  nombre: string;
  redId: number;
  color: string;
  cantidadKilometros: number;
  canRecogidaFecha: boolean;
  canEnvioFecha: boolean;
  radioKm: number;
}

export class RedServicio {
  id: number;
  esActivo: boolean;
  nombre: string;
  redId: number;
  color: string;
  cantidadKilometros: number;
  canRecogidaFecha: boolean;
  canEnvioFecha: boolean;
  radioKm: number;


  constructor({
    id,
    esActivo,
    nombre,
    redId,
    canEnvioFecha,
    canRecogidaFecha,
    cantidadKilometros,
    color,
    radioKm
  }: TBody) {
    this.id = id
    this.esActivo = esActivo
    this.nombre = nombre
    this.redId = redId
    this.canEnvioFecha = canEnvioFecha
    this.canRecogidaFecha = canRecogidaFecha
    this.cantidadKilometros = cantidadKilometros
    this.color = color
    this.radioKm = radioKm
  }

  static toDomain({
    canEnvioFecha,
    canRecogidaFecha,
    cantidadKilometros,
    color,
    esActivo,
    id,
    nombre,
    radioKm,
    redId

  }: TRaw): RedServicio {
    return {
      canEnvioFecha,
      canRecogidaFecha,
      cantidadKilometros,
      color,
      esActivo,
      id,
      nombre,
      radioKm,
      redId,
    }
  }
}

