import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/gestion-facturas',
    name: 'gestionFacturas',
    component: ()=>import('./listar'),
    meta:{
      title: "GESTIÓN FACTURAS",
      permiso:'gestion_facturas_acceso'
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
  {
    path: '/gestion-facturas/form/:id?',
    name: 'gestionFacturas.form',
    component: ()=>import('./form'),
    meta:{
      title: "GESTIÓN FACTURAS FORMULARIO"
    },
    beforeEnter: [authMiddleware,userEnterRoute]
  },
]