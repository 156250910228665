import { createRouter, createWebHistory } from 'vue-router'
import Configuracion from '../views/configuracion/routes'
import Auth from '../views/auth/routes'
import checkAuth from '../middleware/checkAuth.middleware'
import authMiddleware from '@/middleware/auth.middleware'
import dashboard from '../views/dashboard/routes'
import traficoRoutes from '../views/trafico/routes'
import usuariosRoutes from '../views/usuarios/routes'
import clientesRoutes from '../views/clientes/routes'
import redesRoutes from '../views/redes/routes'
import blogRouters from '../views/blog/routes'
import tarifasRouters from '../views/tarifas/routes'
import gestionFacturasRouters from '../views/gestionFacturas/routes'
import facturarEnviosRouters from '../views/facturarEnvios/routes'
import almancenRouters from "../views/almacen/routes"
import paymentRoutes from '../views/payment/routes'
import traficoV2Router from '../views/trafico/v2/router'
import wareHouseRouter from '../views/warehouse/routes'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../views/inicio'),
    beforeEnter: [authMiddleware],
  },
  {
    path: '/403',
    name: 'noAutorizado',
    component: ()=>import('../views/errors/403'),
    beforeEnter: [authMiddleware],
  },
  ...Auth,
  ...Configuracion,
  ...traficoRoutes,
  ...usuariosRoutes,
  ...clientesRoutes,
  ...redesRoutes,
  ...blogRouters,
  ...gestionFacturasRouters,
  ...tarifasRouters,
  ...facturarEnviosRouters,
  ...almancenRouters,
  ...dashboard,
  ...paymentRoutes,
  ...traficoV2Router,
  ...wareHouseRouter,
  {
    path: "/:pathMatch(.*)*",
    component: ()=>import('../views/errors/404')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(checkAuth)

router.afterEach((to)=>{
  const {title} = to.meta
  document.title = `${title|| 'INICIO'} | TXD`
})

export default router
