import { createStore } from 'vuex'

import modulesLocal from './modules'
import envioModule from '../boundedContext/trafico/infrastructure/store/envio'

const modules = { 
  ...modulesLocal,
  envioModule
}

const store = createStore({
  modules
})

export function useStore () {
  return store
}

export default store