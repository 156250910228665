/* eslint-disable @typescript-eslint/no-explicit-any */
import {EnvioByIdUseCase} from '../../application/envioByIdUseCase'
import {EnvioRepository} from '../repositories/envioRepository'
import {GetterTree} from 'vuex'
interface State {
  isLoading: boolean;
  contador: number;
  envio: {
    id: number;
    nombres: string;
  };
}

const store = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      contador: 1,
      envio: {
        id: 111,
        nombres: 'carlos',
      }
    }
  },
  mutations: {
    setIsloading(state:State,payload:boolean){
      state.isLoading = payload
    },
    setContador(state:State){
      console.log(1111);
      state.contador = state.contador+1
    },
    setEnvio(state:any, payload:object) {
      state.envio = payload
    }
  },
  actions: {
    async fetchEnvio(context:any , { id }: { id: string }) {
      context.state.isLoading = true
      const repo = new EnvioRepository()
      const useCase = new EnvioByIdUseCase(repo)
      const result = await useCase.run({ id })
      context.commit('setEnvio', result)
      //context.state.isLoading = false
    }
  },
  getters: {
    getContador: (state:GetterTree<State,object>) => state.contador,
    getEnvio(state:GetterTree<State,object>) {
      return state.envio
    }
  },
}

export default store
