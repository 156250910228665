import store from "@/store";

export default async (to, from, next) => {

    const user = store.getters['auth/user']

    if (!to.meta.permiso) return next()

    if (user.permisos.some((a)=>a === to.meta.permiso) || user.roles.some((a)=> a === 1)) {
        next()
    } else {
        next({ name: 'noAutorizado' })
    }
}
