import { RouteRecordRaw } from 'vue-router'
import { modulo as module } from './const'
import ordenRoutes from './orden/routes'

const routes: RouteRecordRaw[] = [
  {
    path: `/${module}/orden/formulario`,
    name: `${module}.orden`,
    component: () => import('./orden/ordenForm')
  },
  {
    path: `/${module}`,
    name: module,
    redirect: `${module}/productos`,
    component: () => import('./index.vue'),
    children: [
      ...ordenRoutes,
      {
        path: `inventario`,
        name: `${module}.inventario.listar`,
        component: () => import('./inventario/listar/index.vue')
      },
      {
        path: `productos/formulario`,
        name: `${module}.productos.form`,
        component: () => import('./productos/productoForm'),
      },
      {
        path: `productos`,
        name: `${module}.productos`,
        component: () => import('./productos'),
      },
    ]
  }
]

export default routes
