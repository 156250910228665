import { Ciudad } from "@/boundedContext/base/domain/domain/entities/ciudad";
export interface ICiudad {
  id: number;
  name: string;
}
export type Traw = {
  nombre: string;
  direccion: string;
  codigoPostal: string;
  complemento: string;
  ciudadId: number;
  movil: string;
  correo: string;
  latitud: number;
  longitud: number;
  dni: string | null;
  ciudad: ICiudad;
}

export type TBody = {
  nombre: string;
  direccion: string;
  codigoPostal: string;
  complemento: string;
  ciudadId: number;
  movil: string;
  correo: string;
  latitud: number;
  longitud: number;
  dni: string | null;
  ciudad: Ciudad;
}

export class EnvioOrigen {
  nombre: string;
  direccion: string;
  codigoPostal: string;
  complemento: string;
  ciudadId: number;
  movil: string;
  correo: string;
  dni: string | null;
  latitud: number;
  longitud: number;
  ciudad: Ciudad

  constructor(body: TBody) {
    this.nombre = body.nombre
    this.direccion = body.direccion
    this.codigoPostal = body.codigoPostal
    this.complemento = body.complemento
    this.ciudadId = body.ciudadId
    this.movil = body.movil
    this.correo = body.correo
    this.dni = body.dni
    this.latitud = body.latitud
    this.longitud = body.longitud
    this.ciudad = body.ciudad
  }

  static toDomain(raw: Traw) {
    return new EnvioOrigen({
      ciudadId: raw.ciudadId,
      codigoPostal: raw.codigoPostal,
      complemento: raw.complemento,
      correo: raw.correo,
      direccion: raw.direccion,
      latitud: raw.latitud,
      longitud: raw.longitud,
      movil: raw.movil,
      nombre: raw.nombre,
      dni: raw.dni,
      ciudad: Ciudad.toDomain({
        id: raw.ciudad.id,
        nombre: raw.ciudad.name
      })
    })
  }
}
