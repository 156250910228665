import { RouteRecordRaw } from "vue-router";
import { modulo } from '../const'

const routes: RouteRecordRaw[] = [
  {
    path: `orden/listar`,
    name: `${modulo}.ordenes.listar`,
    component: () => import('./listar/index')
  }
]

export default routes
