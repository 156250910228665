import {io} from 'socket.io-client'

//wss://t-xd.socket.mezuticss.es
const socket = io ( process.env.VUE_APP_HOST_SOCKET ?? 'no-host-socket')

socket.on("connect", () => {
  console.log('socket connectado?' ,socket.connected); // true
});

socket.on("disconnect", () => {
  console.log('desconectado',socket.connected); // false
});




export default socket