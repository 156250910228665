import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/almacen',
    name: 'almacen',
    component: () => import('./listar'),
    meta: {
      title:'ALMACEN',
      permiso: 'almacen_acceso'
    },
    beforeEnter:[authMiddleware,userEnterRoute]
  }
]
