import authMiddleware from '@/middleware/auth.middleware'
import userEnterRoute from '@/middleware/checkEnterRoute.middleware'
export default [
  {
    path: '/configuracion/dependencias',
    name: 'configuracion.dependencias',
    component: ()=>import('./listar'),
    beforeEnter: [authMiddleware,userEnterRoute],
    meta:{
      title: 'DEPENDENCIAS',
      permiso:'configuracion_dependencias_acceso'
    }
  },
  
]