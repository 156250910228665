import axios from '../../libs/axios.lib'

const state = ()=>{
  return {
    listado: [
      //{title: 'Nuevo cambio',message: 'El mensajero a cambiado para el envio kjkjk4422',createdAt: '2022-09-06'},
      //{title: 'Nuevo cambio',message: 'El mensajero a cambiado para el envio kjkjk4422',createdAt: '2022-09-06'},      
    ]
  }
}

const getters = {
  getListado: state => state.listado
}

const mutations = {
  setListado(state,payload){
    state.listado = payload
  }
}

const actions = {
  async fetchNotify({commit},payload){
    const {data} = await axios.get(`${process.env.VUE_APP_HOST_NOTIFICACIONES}/api/notificaciones?servicio=2`,payload)
    commit('setListado',data.data)
  }
}



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}